import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";
import {App as AntdApp} from "antd";

const AuthRoute = props => {

    console.log("AuthRoute", props.type);

    const { userSession, type } = props;

    if (type === "guest" && userSession.conectado) return <Redirect to="/ws" />;

    else if (type === "private" && !userSession.conectado) return <Redirect to="/?" />;

    return <Route {...props} />;

};

const mapStateToProps = function( { userSession } ) {

    return {
        userSession: userSession
    };

};

export default connect(mapStateToProps)(AuthRoute);
