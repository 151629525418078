import React from "react";
import { Redirect } from "react-router";
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import {App as AntdApp} from "antd";

const ExactPathRoute = props => {

    console.log("-> ExactPathRoute ->");

    return <Route exact={true} {...props} />;

};

const mapStateToProps = function( { userSession } ) {

    return {
        userSession: userSession
    };

};

export default connect(mapStateToProps)(ExactPathRoute);
