//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../../assets/css/tema.scss';
import "./style.scss";

import {Button} from "antd";
import React from "react";
import {campoInformado} from "../../../lib/iy2b-javascript";

const IY2BProFormButton = (props) => {

    const { width, label, type="default", buttonClassName, onClick, icon, hidden=false } = props;

    let block = false;

    const className = ["iy2b-pro-form-button", "ant-form-item"];

    if(campoInformado(width)) {
        block = true;
        className.push("pro-field");
        className.push("pro-field-" + width);
    }

    if(hidden===true) {
        block = false;
        className.push("not-visible");
    }

    return (
        <div className={className.toClassName()}>
            <div className={"ant-form-item-label"}>&nbsp;</div>
            <Button type={type} className={buttonClassName} onClick={onClick} block={block} icon={icon}>
                {label}
            </Button>
        </div>
    );
}

export default IY2BProFormButton;
