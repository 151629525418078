import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import {App as AntdApp} from "antd";

const LoginRoute = props => {

    console.log("-> LoginRoute ->");

    const { location } = props;

    return <Redirect to={{
        ...location,
        pathname: "/login"
    }}/>;

};

const mapStateToProps = function( { userSession } ) {

    return {
        userSession: userSession
    };

};

export default connect(mapStateToProps)(LoginRoute);
