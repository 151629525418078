import React from "react";
import PageContent from "../framework/componentes/PageContent";
import {lazyRetry} from "../framework/lib/iy2b-react";

// React.lazy
// https://reactjs.org/docs/code-splitting.html
const MovimEstq = React.lazy(() => lazyRetry(() => import('../transacoes/MovimEstq/movimEstq')), null, null, 85052);
const Produto = React.lazy(() => lazyRetry(() => import('../transacoes/Produto/produto')), null, null, 85049);
const CategoriaProduto = React.lazy(() => lazyRetry(() => import('../transacoes/CategoriaProduto/categoriaProduto'), null, null, 85048));
const ProgramacaoPagto = React.lazy(() => lazyRetry(() => import('../transacoes/ProgramacaoPagtos/programacaoPagto'), null, null, 85047));
const TeleProcessamentoCP = React.lazy(() => lazyRetry(() => import('../transacoes/TeleProcessamento/CP/teleProcessamentoCP'), null, null, 85046));
const TeleProcessamentoCR = React.lazy(() => lazyRetry(() => import('../transacoes/TeleProcessamento/CR/teleProcessamentoCR'), null, null, 85045));
const DrillDownCR = React.lazy(() => lazyRetry(() => import('../transacoes/DrillDown/CR/drillDownCR'), null, null, 85044));
const DrillDownCP = React.lazy(() => lazyRetry(() => import('../transacoes/DrillDown/CP/drillDownCP'), null, null, 85043));
const CResumoFin = React.lazy(() => lazyRetry(() => import('../transacoes/CResumoFin/CResumoFin'), null, null, 85042));
const GrupoClassifT = React.lazy(() => lazyRetry(() => import('../transacoes/GrupoClassifT/grupoClassifT'), null, null, 85041));
const GrupoTipoRec = React.lazy(() => lazyRetry(() => import('../transacoes/GrupoTipoRec/grupoTipoRec'), null, null, 85040));
const Cliente = React.lazy(() => lazyRetry(() => import('../transacoes/Cliente/cliente'), null, null, 85039));
const GrupoTipoDesp = React.lazy(() => lazyRetry(() => import('../transacoes/GrupoTipoDesp/grupoTipoDesp'), null, null, 85038));
const ConsultaFluxoCaixa = React.lazy(() => lazyRetry(() => import('../transacoes/ConsultaFluxoCaixa/consultaFluxoCaixa'), null, null, 85037));
const ContratoVenda = React.lazy(() => lazyRetry(() => import('../transacoes/ContratoVenda/contratoVenda'), null, null, 85036));
const TipoContratoVenda = React.lazy(() => lazyRetry(() => import('../transacoes/TipoContratoVenda/tipoContratoVenda'), null, null, 85035));
const CustosExtras = React.lazy(() => lazyRetry(() => import('../transacoes/CustosExtras/custosExtras'), null, null, 85034));
const ConsultaDRE = React.lazy(() => lazyRetry(() => import('../transacoes/ConsultaDRE/consultaDRE'), null, null, 85033));
const FormaRecebimento = React.lazy(() => lazyRetry(() => import('../transacoes/FormaRecebimento/formaRecebimento'), null, null, 85032));
const PedidoVenda = React.lazy(() => lazyRetry(() => import('../transacoes/PedidoVenda/pedidoVenda'), null, null, 85031));
const CondicaoPagto = React.lazy(() => lazyRetry(() => import('../transacoes/CondicaoPagto/condicaoPagto'), null, null, 85024));
const ServicoPrestado = React.lazy(() => lazyRetry(() => import('../transacoes/ServicoPrestado/servicoPrestado'), null, null, 85023));
const TipoServico = React.lazy(() => lazyRetry(() => import('../transacoes/TipoServico/tipoServico'), null, null, 85022));
const TipoReceita = React.lazy(() => lazyRetry(() => import('../transacoes/TipoReceita/tipoReceita'), null, null, 85021));
const CItemSP01 = React.lazy(() => lazyRetry(() => import('../transacoes/CItemSP01/cItemSP01'), null, null, 85020));
const Dashboard02 = React.lazy(() => lazyRetry(() => import('../transacoes/Dashboard02/dashboard02'), null, null, 85019));
const ClassifT = React.lazy(() => lazyRetry(() => import('../transacoes/ClassifT/classifT'), null, null, 85018));
const Credor = React.lazy(() => lazyRetry(() => import('../transacoes/Credor/credor'), null, null, 85017));
const ManutencaoSP = React.lazy(() => lazyRetry(() => import('../transacoes/ManutencaoSP/msp'), null, null, 85016));
const CartaoCredito = React.lazy(() => lazyRetry(() => import('../transacoes/CartaoCredito/cartaoCredito'), null, null, 85015));
const ProjetoCM = React.lazy(() => lazyRetry(() => import('../transacoes/ProjetoCM/projetoCM'), null, null, 85014));
const AreaCC = React.lazy(() => lazyRetry(() => import('../transacoes/AreaCC/areaCC'), null, null, 85013));
const TipoDespesa = React.lazy(() => lazyRetry(() => import('../transacoes/TipoDespesa/tipoDespesa'), null, null, 85012));
const ConciliacaoBancaria = React.lazy(() => lazyRetry(() => import('../transacoes/ConciliacaoBancaria/conciliacaoBancaria'), null, null, 85011));
const ContaBancaria = React.lazy(() => lazyRetry(() => import('../transacoes/ContaBancaria/contaBancaria'), null, null, 85010));
const GeracaoSPRecorrente = React.lazy(() => lazyRetry(() => import('../transacoes/GeracaoSPRecorrente/geracaoSPRecorrente'), null, null, 85009));
const ModeloSP = React.lazy(() => lazyRetry(() => import('../transacoes/ModeloSP/ModeloSP'), null, null, 85007));
const Dashboard01 = React.lazy(() => lazyRetry(() => import('../transacoes/Dashboard01'), null, null, 85002));
const ConsultaNotas = React.lazy(() => lazyRetry(() => import('../transacoes/ConsultaNotas'), null, null, 85001));
const ReguaCobEmp = React.lazy(() => lazyRetry(() => import('../transacoes/ReguaCobEmp/reguaCobEmp'), null, null, 85054));
const FaturaCartCredCP = React.lazy(() => lazyRetry(() => import('../transacoes/FaturaCartCredCP/faturaCartCredCP'), null, null, 85055));
const TipoOrdemServico = React.lazy(() => lazyRetry(() => import('../transacoes/TipoOrdemServico/tipoOrdemServico'), null, null, 85057));
const TarefaOrdemServico = React.lazy(() => lazyRetry(() => import('../transacoes/TarefaOrdemServico/tarefaOrdemServico'), null, null, 85058));
const NovaOrdemServico = React.lazy(() => lazyRetry(() => import('../transacoes/NovaOrdemServico/novaOrdemServico'), null, null, 85060));
const Almoxarifado = React.lazy(() => lazyRetry(() => import('../transacoes/Almoxarifado/almoxarifado'), null, null, 85066));
const Atendimento = React.lazy(() => lazyRetry(() => import('../transacoes/Atendimento/atendimento'), null, null, 85068));
const PautaTrabalho = React.lazy(() => lazyRetry(() => import('../transacoes/PautaTrabalho/pautaTrabalho'), null, null, 85069));
const OrcamentoFin = React.lazy(() => lazyRetry(() => import('../transacoes/OrcamentoFin/orcamentoFin'), null, null, 85070));
const ConsultaOrcamento = React.lazy(() => lazyRetry(() => import('../transacoes/ConsultaOrcamento/consultaOrcamento'), null, null, 85071));
const EnviarFaturamento = React.lazy(() => lazyRetry(() => import('../transacoes/EnviarFaturamento/enviarFaturamento'), null, null, 85072));
const ControleFechamentos = React.lazy(() => lazyRetry(() => import('../transacoes/ControleFechamentos/controleFechamentos'), null, null, 85073));
const ExtratoBancario = React.lazy(() => lazyRetry(() => import('../transacoes/ExtratoBancario/extratoBancario'), null, null, 85074));
const MovimentacaoIndividualCR = React.lazy(() => lazyRetry(() => import('../transacoes/MovIndivTitCR/movimentacaoIndividualCR'), null, null, 85075));
const SerieTipoVendaEmp = React.lazy(() => lazyRetry(() => import('../transacoes/SerieTipoVendaEmp/serieTipoVendaEmp'), null, null, 85076));
const VendaExpressaServico = React.lazy(() => lazyRetry(() => import('../transacoes/VendaExpressaServico/vendaExpressaServico'), null, null, 85078));
const TributacaoPorProduto = React.lazy(() => lazyRetry(() => import('../transacoes/TributacaoPorProduto/tributacaoPorProduto'), null, null, 85079));
const GrupoAcessoGED = React.lazy(() => lazyRetry(() => import('../transacoes/GrupoAcessoGED/grupoAcessoGED'), null, null, 85081));
const EtiquetaGED = React.lazy(() => lazyRetry(() => import('../transacoes/EtiquetasGED/etiquetaGED'), null, null, 85082));
const GED = React.lazy(() => lazyRetry(() => import('../transacoes/GED/ged'), null, null, 85084));
const TipoAtendXPerfil = React.lazy(() => lazyRetry(() => import('../transacoes/TipoAtendXPerfil/tipoAtendXPerfil'), null, null, 85085));
const MeuDashboard = React.lazy(() => lazyRetry(() => import('../transacoes/MeuDashboard/meuDashboard'), null, null, 85086));
const ClassifDRE = React.lazy(() => lazyRetry(() => import('../transacoes/ClassifDRE/classifDRE'), null, null, 85090));
const ExportacaoArqSQL = React.lazy(() => lazyRetry(() => import('../transacoes/ExportacaoArqSQL/exportacaoArqSQL'), null, null, 85093));
const NFInterna = React.lazy(() => lazyRetry(() => import('../transacoes/NFInterna/nfInterna'), null, null, 85096));
const NFVDigitada = React.lazy(() => lazyRetry(() => import('../transacoes/NFVDigitada/nfvDigitada'), null, null, 85097));
const DrillDownPauta = React.lazy(() => lazyRetry(() => import('../transacoes/DDownPautaTrabalho/drillDownPauta'), null, null, 85098));
const DashboardComercial = React.lazy(() => lazyRetry(() => import('../transacoes/DashboardComercial/dashboardComercial'), null, null, 85099));
const CadNroSerieProduto = React.lazy(() => lazyRetry(() => import('../transacoes/CadNroSerieProduto/cadNroSerieProduto'), null, null, 85101));
const ConsultaNroSerieProduto = React.lazy(() => lazyRetry(() => import('../transacoes/ConsultaNroSerieProduto/consultaNroSerieProduto'), null, null, 85102));
const Corretor = React.lazy(() => lazyRetry(() => import('../transacoes/Corretor/corretor'), null, null, 85105));
const ImportacaoArquivos = React.lazy(() => lazyRetry(() => import('../transacoes/ImportacaoArquivos/importacaoArquivos'), null, null, 85106));


const listaTransacoes = [{
        cdTransacao: 85001,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ConsultaNotas
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85002,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><Dashboard01
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85007,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ModeloSP
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85009,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><GeracaoSPRecorrente
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85010,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ContaBancaria
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85011,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ConciliacaoBancaria
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85012,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><TipoDespesa
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85013,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><AreaCC
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85014,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ProjetoCM
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85015,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><CartaoCredito
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85016,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ManutencaoSP
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85017,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><Credor
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85018,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ClassifT
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85019,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><Dashboard02
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85020,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><CItemSP01
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85021,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><TipoReceita
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85022,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><TipoServico
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85023,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><ServicoPrestado
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85024,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><CondicaoPagto
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
    }, {
        cdTransacao: 85031,
        page: (cdTransacao, paramWeb) => {
            return (
                <PageContent
                    tcKey={cdTransacao}
                ><PedidoVenda
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
                </PageContent>
            );
        }
}, {
    cdTransacao: 85032,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><FormaRecebimento
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85033,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><ConsultaDRE
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85034,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><CustosExtras
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85035,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><TipoContratoVenda
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85036,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><ContratoVenda
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85037,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><ConsultaFluxoCaixa
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85038,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><GrupoTipoDesp
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85039,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><Cliente
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85040,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><GrupoTipoRec
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85041,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><GrupoClassifT
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85042,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><CResumoFin
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85043,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><DrillDownCP
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85044,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><DrillDownCR
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85045,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><TeleProcessamentoCR
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85046,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><TeleProcessamentoCP
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85047,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><ProgramacaoPagto
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85048,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><CategoriaProduto
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85049,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><Produto
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85050,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            ><PedidoVenda
                cdTransacao={cdTransacao}
                paramWeb={paramWeb}
            />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85052,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <MovimEstq
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85054,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <ReguaCobEmp
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85055,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <FaturaCartCredCP
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85057,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <TipoOrdemServico
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85058,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <TarefaOrdemServico
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85060,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <NovaOrdemServico
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85066,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <Almoxarifado
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85068,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <Atendimento
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85069,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <PautaTrabalho
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85070,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <OrcamentoFin
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85071,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <ConsultaOrcamento
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85072,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <EnviarFaturamento
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85073,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <ControleFechamentos
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85074,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <ExtratoBancario
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85075,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <MovimentacaoIndividualCR
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85076,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <SerieTipoVendaEmp
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85078,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <VendaExpressaServico
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85079,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <TributacaoPorProduto
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85081,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <GrupoAcessoGED
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85082,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <EtiquetaGED
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85084,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <GED
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85085,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <TipoAtendXPerfil
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85086,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <MeuDashboard
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85090,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <ClassifDRE
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85093,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <ExportacaoArqSQL
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85096,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <NFInterna
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85097,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <NFVDigitada
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85098,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <DrillDownPauta
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85099,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <DashboardComercial
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85100,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <CustosExtras
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85101,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <CadNroSerieProduto
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85102,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <ConsultaNroSerieProduto
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85105,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <Corretor
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}, {
    cdTransacao: 85106,
    page: (cdTransacao, paramWeb) => {
        return (
            <PageContent
                tcKey={cdTransacao}
            >
                <ImportacaoArquivos
                    cdTransacao={cdTransacao}
                    paramWeb={paramWeb}
                />
            </PageContent>
        );
    }
}];
//


const transacaoToPage = {
    get: (cdTransacao, paramWeb) => {
        const paraRodar = listaTransacoes.filter(item => item.cdTransacao === cdTransacao);
        if(paraRodar.length === 1) {
            return paraRodar[0].page(cdTransacao, paramWeb);
        } else {
            return null;

        }
    }
};


export default transacaoToPage;
